import React from "react"
import ReactMarkdown from "react-markdown";
import SEO from "../components/common/seo";
import Breadcrumbs from "../components/common/breadcrumbs";
import LayoutSecondary from "../components/common/layout-secondary";
import {graphql} from 'gatsby'
import {Link} from "@reach/router";
import {sortAsc, customElements} from "../utils/global-functions";
import rehypeRaw from "rehype-raw";

const CloudNativePage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const dsc = data.allStrapiDesarrolloCloudNative.edges[0].node;
    const iaItems = dsc.ia_items.sort(sortAsc);
    const machineLearningItems = dsc.machine_learning_items.sort(sortAsc);
    const cloudItems = dsc.cloud_items.sort(sortAsc);

    const breadcrumbs = [
        {text: 'Servicios'},
        {text: dsc.title, active: true},
    ];

    return (
        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={dsc.seo.title} description={dsc.seo.meta_description}/>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>

            {/** INTRODUCCIÓN
             ================================================== */}
            <section className="postition-relative pt-8 pt-md-11 mb-11 bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12 col-md-6 col-xl-7" data-aos="fade-up">

                            {/** Heading */}
                            <h1 className="display-4 font-weight-bold spacing mb-6">
                                {dsc.title}
                            </h1>

                            <ReactMarkdown children={dsc.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>

                        </div>

                        <div className="col-12 col-md-6 col-xl-4 offset-xl-1">

                            {/** Decoration */}
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: "absolute", top: "-40px", right: "-20px", zIndex: "-99"}}>
                                <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="182" r="2" fill="#f43c51"/>
                                </svg>
                            </div>

                            {/** Image */}
                            <img src={require("../images/cloud-native_01.png").default} alt="..."
                                 className="img-fluid rounded mw-130 float-left mb-6 mb-md-0" data-aos="fade-left"/>

                        </div>
                    </div>
                    {/** / .row */}

                </div>
                {/** / .container */}
            </section>

            {/** CULTURA TECALIS / AGILE / CLOUD NATIVE
             ================================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 bg-static">

                <div className="bg-move-cloud"/>

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-4">

                            {/** Decoration */}
                            <div className="position-absolute top-md-left text-warning mt-n9 m-md-n9"
                                 style={{position: "absolute", zIndex: "-9", right: "40px", bottom: "30px"}}>
                                <svg width="278" height="278" viewBox="0 0 278 278" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffffff" d="M3 0h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 3h8v2H0zM33 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 3h8v2h-8zM63 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 3h8v2h-8zM93 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 3h8v2h-8zM123 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 3h8v2h-8zM153 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 3h8v2h-8zM183 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 3h8v2h-8zM213 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 3h8v2h-8zM243 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 3h8v2h-8zM273 0h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 3h8v2h-8zM3 30h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 33h8v2H0zM33 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 33h8v2h-8zM63 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 33h8v2h-8zM93 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 33h8v2h-8zM123 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 33h8v2h-8zM153 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 33h8v2h-8zM183 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 33h8v2h-8zM213 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 33h8v2h-8zM243 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 33h8v2h-8zM273 30h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 33h8v2h-8zM3 60h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 63h8v2H0zM33 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 63h8v2h-8zM63 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 63h8v2h-8zM93 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 63h8v2h-8zM123 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 63h8v2h-8zM153 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 63h8v2h-8zM183 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 63h8v2h-8zM213 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 63h8v2h-8zM243 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 63h8v2h-8zM273 60h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 63h8v2h-8zM3 90h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 93h8v2H0zM33 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 93h8v2h-8zM63 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 93h8v2h-8zM93 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 93h8v2h-8zM123 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 93h8v2h-8zM153 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 93h8v2h-8zM183 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 93h8v2h-8zM213 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 93h8v2h-8zM243 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 93h8v2h-8zM273 90h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 93h8v2h-8zM3 120h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 123h8v2H0zM33 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 123h8v2h-8zM63 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 123h8v2h-8zM93 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 123h8v2h-8zM123 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 123h8v2h-8zM153 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 123h8v2h-8zM183 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 123h8v2h-8zM213 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 123h8v2h-8zM243 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 123h8v2h-8zM273 120h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 123h8v2h-8zM3 150h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 153h8v2H0zM33 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 153h8v2h-8zM63 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 153h8v2h-8zM93 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 153h8v2h-8zM123 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 153h8v2h-8zM153 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 153h8v2h-8zM183 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 153h8v2h-8zM213 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 153h8v2h-8zM243 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 153h8v2h-8zM273 150h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 153h8v2h-8zM3 180h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 183h8v2H0zM33 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 183h8v2h-8zM63 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 183h8v2h-8zM93 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 183h8v2h-8zM123 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 183h8v2h-8zM153 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 183h8v2h-8zM183 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 183h8v2h-8zM213 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 183h8v2h-8zM243 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 183h8v2h-8zM273 180h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 183h8v2h-8zM3 210h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 213h8v2H0zM33 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 213h8v2h-8zM63 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 213h8v2h-8zM93 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 213h8v2h-8zM123 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 213h8v2h-8zM153 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 213h8v2h-8zM183 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 213h8v2h-8zM213 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 213h8v2h-8zM243 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 213h8v2h-8zM273 210h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 213h8v2h-8zM3 240h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 243h8v2H0zM33 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 243h8v2h-8zM63 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 243h8v2h-8zM93 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 243h8v2h-8zM123 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 243h8v2h-8zM153 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 243h8v2h-8zM183 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 243h8v2h-8zM213 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 243h8v2h-8zM243 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 243h8v2h-8zM273 240h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 243h8v2h-8zM3 270h2v8H3z"/>
                                    <path fill="#ffffff" d="M0 273h8v2H0zM33 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M30 273h8v2h-8zM63 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M60 273h8v2h-8zM93 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M90 273h8v2h-8zM123 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M120 273h8v2h-8zM153 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M150 273h8v2h-8zM183 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M180 273h8v2h-8zM213 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M210 273h8v2h-8zM243 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M240 273h8v2h-8zM273 270h2v8h-2z"/>
                                    <path fill="#ffffff" d="M270 273h8v2h-8z"/>
                                </svg>
                            </div>

                            {/** Image */}
                            <img src={require("../images/cloud-native_02.png").default} alt="..."
                                 className="img-fluid rounded mw-130 float-right mb-6 mb-md-0" data-aos="fade-right"/>

                        </div>
                        <div className="col-12 col-md-6 col-lg-7 offset-lg-1" data-aos="fade-up">

                            <h2 className="display-4 font-weight-bold text-white-move spacing mb-6">
                                {dsc.culture_title}
                            </h2>

                            <ReactMarkdown children={dsc.culture_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg mb-6 spacing text-white-move')}/>
                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>

            {/** Desarrollo y trasformación en base Cloud
             ============================================= */}
            <section className="position-relative py-8 py-md-11 mb-9">

                {/** Content */}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {dsc.development_title}
                            </h2>

                            {/** Divider */}
                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>

                        </div>
                    </div>
                    {/** / .row */}
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-5">

                            {/** Image */}
                            <img src={require("../img/base-cloud.svg").default} alt="..."
                                 className="img-fluid rounded mw-130 float-right mb-6 mb-md-0" data-aos="fade-right"/>
                            {/**- Deco */}
                            {/*<img src="" className="img-fluid" data-aos="fade-right"/>*/}

                        </div>
                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1" data-aos="fade-up">

                            <ReactMarkdown children={dsc.development_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>


            {/** Todo producto digital que no incorpore IA nace obsoleto
             ============================================================= */}
            <section className="pt-8 pt-md-9 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {dsc.ia_title}
                            </h2>

                            {/** Divider */}
                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>

                        </div>
                    </div>
                    {/** / .row */}
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">

                            {/** Card */}
                            <div className="card rounded-lg shadow-lg mt-2 mb-6 mb-md-0" style={{zIndex: "1"}}
                                 data-aos="fade-up">

                                {/** Body */}
                                <div className="card-body py-6 py-md-8">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-xl-9">

                                            <ReactMarkdown children={dsc.ia_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>

                                        </div>
                                    </div>
                                </div>
                                {/** / .row */}
                            </div>

                        </div>

                    </div>

                </div>
                {/** / .row */}
            </section>

            {/** SHAPE
             ================================================== */}
            <div className="position-relative mt-n15">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-dark">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="#f4364c"/>
                    </svg>
                </div>
            </div>

            <section className="pt-15 bg-tecalis pb-10">
                <div className="container pt-8 pt-md-11">

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">

                            <div className="row">
                                {iaItems.map((item, i) => {
                                    return <div className="col-12 col-md-6">
                                        <div className="d-flex">
                                            <div className="ml-5">
                                                <div className="icons text-primary mb-2">
                                                    <img src={item.icon.localFile.publicURL} alt={item.title}/>
                                                </div>

                                                <br/>

                                                <h3 className="text-white font-weight-black spacing">
                                                    {item.title}
                                                </h3>

                                                {/** Text */}
                                                <p className="font-size-md mb-6 mb-md-6 text-white spacing">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>

                        </div>
                    </div>
                </div>
                {/** / .container */}
            </section>


            {/** MACHINE LEARNING PARA POTENCIAR EL BIG DATA
             ================================================== */}
            <section className="py-8 py-md-11 bg-grey border-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-offset-1" data-aos="fade-up">
                            {/** Heading */}
                            <h2 align="center" className="display-4 font-weight-bold spacing">
                                {dsc.machine_learning_title}
                            </h2>
                            {/** Divider */}
                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>

                            <div className="row mt-3">
                                <div className="col-xl-8" data-aos="fade-up">

                                    {/** Card */}
                                    <div className="card accordion" id="featuresAccordion">
                                        <div className="card-body">

                                            {/** List group */}
                                            <div className="list-group list-group-flush">

                                                <div className="list-group-item">
                                                    {/** Header */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="mr-auto">
                                                            <ReactMarkdown children={dsc.machine_learning_description}
                                                                           rehypePlugins={[rehypeRaw]}
                                                                           components={customElements('font-weight-regular mt-2 spacing')}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                {machineLearningItems.map((item, i) => {
                                                    return <div className="list-group-item">
                                                        <a className="d-flex align-items-center text-reset text-decoration-none"
                                                           data-toggle="collapse" href={"#features-" + item.order} role="button"
                                                           aria-expanded="false" aria-controls="featuresOne" key={i}>
                                                            <div className="mr-auto">
                                                                <span className="mr-4 font-weight-bold text-tecalis spacing">
                                                                    {item.title}
                                                                </span>
                                                            </div>

                                                            <span className="collapse-chevron text-muted ml-4">
                                                                <i className="fe fe-lg fe-chevron-down"/>
                                                            </span>
                                                        </a>

                                                        <div className="collapse" id={"features-" + item.order}
                                                             data-parent="#featuresAccordion">
                                                            <div className="py-2 py-mb-6 spacing">
                                                                <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-xl-4" data-aos="fade-up">


                                    <div className="position-absolute top-right text-primary-dark mt-n12"
                                         style={{
                                             position: "absolute",
                                             zIndex: "-99",
                                             left: "-40px",
                                             transform: "rotate(90deg)",
                                             bottom: "-50px"
                                         }}>
                                        <svg width="129" height="208" viewBox="0 0 129 208" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#decoration5clip0)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M142.812 201.281a6.47 6.47 0 1112.94.002 6.47 6.47 0 01-12.94-.002zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.468 6.468 0 1112.936 0 6.468 6.468 0 01-12.936 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip1)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M142.812 149.281a6.47 6.47 0 1112.94.002 6.47 6.47 0 01-12.94-.002zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.468 6.468 0 1112.936 0 6.468 6.468 0 01-12.936 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip2)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M142.812 97.281a6.47 6.47 0 1112.939.002 6.47 6.47 0 01-12.939-.002zm1.618 0a4.851 4.851 0 119.703 0 4.851 4.851 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.81-1.143-1.143 4.811-4.81-4.811-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.937 0zm1.617 0a4.851 4.851 0 119.703 0 4.851 4.851 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.81-4.811-4.812 1.143-1.143 4.811 4.81z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip3)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M38.813 149.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.938 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.469 6.469 0 1112.937.001 6.469 6.469 0 01-12.938-.001zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip4)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M38.813 97.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip5)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M38.813 45.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip6)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M90.813 175.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.939 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811zm-32.344 1.143a6.469 6.469 0 1112.938.001 6.469 6.469 0 01-12.938-.001zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip7)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M90.813 123.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.939 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.344 1.143a6.469 6.469 0 1112.938 0 6.469 6.469 0 01-12.938 0zm1.618 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <g clipPath="url(#decoration5clip8)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M90.813 71.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.344 1.143a6.469 6.469 0 1112.938 0 6.469 6.469 0 01-12.938 0zm1.618 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                                      fill="#f43c51"/>
                                            </g>
                                            <defs>
                                                <clipPath id="decoration4clip0">
                                                    <path transform="matrix(0 -1 -1 0 155.75 207.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip1">
                                                    <path transform="rotate(-90 155.75 0)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip2">
                                                    <path transform="matrix(0 -1 -1 0 155.75 103.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip3">
                                                    <path transform="matrix(0 -1 -1 0 51.75 155.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip4">
                                                    <path transform="matrix(0 -1 -1 0 51.75 103.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip5">
                                                    <path transform="matrix(0 -1 -1 0 51.75 51.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip6">
                                                    <path transform="matrix(0 -1 -1 0 103.75 181.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip7">
                                                    <path transform="matrix(0 -1 -1 0 103.75 129.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                                <clipPath id="decoration4clip8">
                                                    <path transform="matrix(0 -1 -1 0 103.75 77.75)" fill="#f43c51"
                                                          d="M0 0h51.75v51.75H0z"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>

                                    {/** Img */}
                                    <img src={require("../images/cloud-native_03.png").default} alt="..."
                                         className="img-fluid rounded mw-130 float-left mb-6 mb-md-0"
                                         data-aos="fade-left"/>
                                </div>

                            </div>
                            {/** / .row */}
                            <br/><br/>
                        </div>
                    </div>
                </div>
                {/** / .container */}
            </section>

            {/** SCRUM
             ================================================== */}
            <section className="pt-8 pt-md-11 mb-12 bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-offset-2" data-aos="fade-up">
                            {/** Heading */}
                            <h2 align="center" className="display-4 font-weight-bold spacing">
                                {dsc.scrum_title}
                            </h2>
                            {/** Divider */}
                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>

                            <ReactMarkdown children={dsc.scrum_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing', 'default-link')}/>

                            {/** Image */}
                            <img src={require("../img/river-sprint.jpg").default} alt="..." className="img-fluid mb-10 mt-4"
                                 data-aos="fade-up"/>
                        </div>
                    </div>
                    {/** / .row */}

                </div>
                {/** / .container */}
            </section>

            <section className="py-8 py-md-11 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-offset-2" data-aos="fade-up">
                            {/** Heading */}
                            <h2 align="center" className="display-4 font-weight-bold spacing">
                                {dsc.cloud_title}
                            </h2>

                            <br/><br/>

                            <ReactMarkdown children={dsc.cloud_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>

                            <br/>

                            {/** Card */}
                            <div className="card shadow-light-lg overflow-hidden text-center text-lg-left"
                                 data-aos="fade-right">
                                <div className="row">

                                    <div className="col-md-12">

                                        {/** Body */}
                                        <div className="card-body mt-3 ml-8 mr-8">

                                            {/** Text */}
                                            <p className="font-size-lg mb-4 spacing">
                                                El uso de contenedores proporciona una mejor uniformidad del entorno, una
                                                implementación más rápida, portabilidad y escalabilidad.
                                            </p>

                                        </div>

                                    </div>
                                </div>
                                {/** / .row */}
                            </div>


                            {cloudItems.map((item, i) => {
                                return <div className="card mt-5 shadow-light-lg overflow-hidden text-center text-lg-left" data-aos={i % 2 === 0 ? 'fade-left' : 'fade-right'}
                                            key={i}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card-body mt-3 ml-8 mr-8">
                                                <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg mb-4 spacing')}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>


                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>

            {/** CLOUD 7X24 - Infraestructuras Diseñadas para el Futuro
             =========================================================== */}
            <section className="postition-relative pt-8 pt-md-11 mb-11 bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12 col-md-6 col-xl-7" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing mb-6">
                                {dsc.infrastructure_title}
                            </h2>

                            <ReactMarkdown children={dsc.infrastructure_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing', 'default-link')}/>
                        </div>

                        <div className="col-12 col-md-6 col-xl-5">

                            {/** Image */}
                            <img src={require("../img/cloud7x24.png").default} alt="..." className="img-fluid rounded mb-6 mb-md-0"
                                 data-aos="fade-left"/>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>


            {/** ISO / LOGOS
             ================================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 border-top">

                {/** Content */}
                <div className="container">

                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-xl-4">
                            {/** Image */}
                            <img src={require("../img/software.png").default} alt="..." className="img-fluid rounded mb-6 mb-md-0"
                                 data-aos="fade-right"/>
                        </div>

                        <div className="col-12 col-md-6 col-xl-8">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing mb-6">
                                {dsc.stack_title}
                            </h2>

                            <ReactMarkdown children={dsc.stack_description}
                                           rehypePlugins={[rehypeRaw]}
                                           components={customElements('font-size-lg text-black-80 mb-6 spacing', 'default-link', 'mb-6 font-weight-bold spacing', 'mb-4 font-weight-bold spacing')}/>
                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>
        </LayoutSecondary>
    )
};

export default CloudNativePage

export const cloudNativePageQuery = graphql
    `query($lang: String) {
        allStrapiDesarrolloCloudNative {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    title
                    description
                    culture_title
                    culture_description
                    development_title
                    development_description
                    ia_title
                    ia_description
                    ia_items {
                        order
                        title
                        description
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    machine_learning_title
                    machine_learning_description
                    machine_learning_items {
                        order
                        title
                        description
                    }
                    scrum_title
                    scrum_description
                    cloud_title
                    cloud_description
                    cloud_items {
                        order
                        title
                        description
                    }
                    infrastructure_title
                    infrastructure_description
                    stack_title
                    stack_description
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: {
                lang: { eq: $lang }
                name: { eq: "cookies" }
            }
        ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
